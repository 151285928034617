import React from "react";
import { Routes, Route } from "react-router-dom";
import Loadable from 'react-loadable';

import Loader from './layout/Loader';
import ROLES from '../config/rolesList';
import PersistLogin from '../features/PersistLogin';
import RequireAuth from '../features/RequireAuth';

const Signin = React.lazy(() => import('./pages/Authentication/SignIn'));

const Account = React.lazy(() => import('./pages/Account'));

const Dashboard = React.lazy(() => import('./pages/Dashboard'));

const OrderList = React.lazy(() => import('./pages/Orders/List'));
const Order = React.lazy(() => import('./pages/Orders/Card'));

const PromoterList = React.lazy(() => import('./pages/Promoter/List'));
const NewPromoter = React.lazy(() => import('./pages/Promoter/New'));
const Promoter = React.lazy(() => import('./pages/Promoter/Card'));

const CustomerList = React.lazy(() => import('./pages/Customers/List'));
const Customer = React.lazy(() => import('./pages/Customers/Card'));

const ProductList = React.lazy(() => import('./pages/Products/List'));
const NewProduct = React.lazy(() => import('./pages/Products/New'));
const Product = React.lazy(() => import('./pages/Products/Card'));

export default function RoutesApp() {
  return (
    <Routes>
      <Route index element={<Signin/>} />

      { /* Auth */ }
      <Route path="/auth/signin" element={<Signin/>} />

      { /* Dashboard */ }
      <Route element={<PersistLogin/>}>
        { /* Require Auth */ }
        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="/account"
            element={<AdminLayout><Account/></AdminLayout>}/>

          <Route path="/dashboard"
            element={<AdminLayout><Dashboard/></AdminLayout>}/>

          <Route path="/dashboard/order-list"
            element={<AdminLayout><OrderList/></AdminLayout>}/>
          <Route path="/dashboard/order/:id"
            element={<AdminLayout><Order/></AdminLayout>}/>

          <Route path="/dashboard/promoter-list"
            element={<AdminLayout><PromoterList/></AdminLayout>}/>
          <Route path="/dashboard/new-promoter"
            element={<AdminLayout><NewPromoter/></AdminLayout>}/>
          <Route path="/dashboard/promoter/:id"
            element={<AdminLayout><Promoter/></AdminLayout>}/>

          <Route path="/dashboard/customer-list"
            element={<AdminLayout><CustomerList/></AdminLayout>}/>
          <Route path="/dashboard/customer/:id"
            element={<AdminLayout><Customer/></AdminLayout>}/>

          <Route path="/dashboard/product-list"
            element={<AdminLayout><ProductList/></AdminLayout>}/>
          <Route path="/dashboard/new-product"
            element={<AdminLayout><NewProduct/></AdminLayout>}/>
          <Route path="/dashboard/product/:id"
            element={<AdminLayout><Product/></AdminLayout>}/>
        </Route>
      </Route>

      { /* Path don't found */ }
      <Route path="/*" element={<>Non trovato</>} />
    </Routes>
  );
};

const AdminLayout = Loadable({
  loader: () => import('./layout/AdminLayout'),
  loading: Loader
});