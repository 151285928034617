import React from 'react';

const loader = () => {
  return (
    <div className="loader-bg">
      <div className="loader-track">
        <div className="loader-fill"/>
      </div>
    </div>
  );
};

export default loader;