const config = {
  defaultPath: '/dashboard',
  basename: '/dashboard',
  layout: 'vertical',
  collapseMenu: false,
  navIconColor: false,
  navFixedLayout: true,
  boxLayout: false,
};

export default config