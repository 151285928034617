import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './app';
import { store } from './app/store';
import { AuthProvider } from './services/context/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={ <App/> }/>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);