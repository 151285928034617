import React, { Suspense } from "react";

import ScrollToTop from '../utils/scrollToTop';
import Loader from './layout/Loader'
import RoutesApp from './routesApp';

export default function App() {
  return (
    <>
      <ScrollToTop/>
      <Suspense fallback={<Loader/>}>
        <RoutesApp/>
      </Suspense>
    </>
  );
}