import { createSlice } from "@reduxjs/toolkit";
import config from '../../config/config';

const initialState = {
  isOpen: [], //for active default menu
  isTrigger: [], //for active default menu, set blank for horizontal
  ...config,
};

let open = [];
let trigger = [];

const statusAppSlice = createSlice({
  name: 'statusApp',
  initialState,
  reducers: {
    collapseMenu(state) {
      state.collapseMenu = !state.collapseMenu;
    },
    collapseToggle(state, action) {
      const { id, type } = action.payload;

      if (type === 'sub') {
        open = state.isOpen;
        trigger = state.isTrigger;

        const triggerIndex = trigger.indexOf(id);
        if (triggerIndex > -1) {
          open = open.filter(item => item !== id);
          trigger = trigger.filter(item => item !== id);
        }

        if (triggerIndex === -1) {
          open = [...open, id];
          trigger = [...trigger, id];
        }
      } else {
        open = state.isOpen;
        const triggerIndex = (state.isTrigger).indexOf(id);
        trigger = (triggerIndex === -1) ? [id] : [];
        open = (triggerIndex === -1) ? [id] : [];
      }

      state.isOpen = open;
      state.isTrigger = trigger;
    },
    navContentLeave(state) {
      state.isOpen = open;
      state.isTrigger = trigger;
    },
    navCollapseLeave(state, action) {
      const { id, type } = action.payload;

      if (type === 'sub') {
        open = state.isOpen;
        trigger = state.isTrigger;

        const triggerIndex = trigger.indexOf(id);
        if (triggerIndex > -1) {
          open = open.filter(item => item !== id);
          trigger = trigger.filter(item => item !== id);
        }

        state.isOpen = open;
        state.isTrigger = trigger;
      }
    },
    changeLayout(state, action) {
      const orientation = action.payload;
      state.layout = orientation;
    }
  }
})

export const selectAllStatusApp = (state) => state.statusApp;

export const { collapseMenu, collapseToggle, navContentLeave, navCollapseLeave, changeLayout } = statusAppSlice.actions

export default statusAppSlice.reducer